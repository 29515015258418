




















































import { Component, Vue } from "vue-property-decorator";
import SiteNavigation from "@/components/SiteNavigation.vue";
import MainHeader from "@/components/widgets/MainHeader.vue";
import Showreel from "@/components/widgets/Showreel.vue";
import Portfolio from "@/components/widgets/Portfolio.vue";
import About from "@/components/widgets/About.vue";
import Credits from "@/components/widgets/Credits.vue";
import ContactForm from "@/components/widgets/ContactForm.vue";
import SiteFooter from "@/components/SiteFooter.vue";

import { SubNavItem } from "@/interfaces/Navigation.interface";
import { PortfolioModel } from "@/models";

@Component({
  components: {
    SiteNavigation,
    MainHeader,
    Showreel,
    Portfolio,
    About,
    Credits,
    ContactForm,
    SiteFooter,
  },
})
export default class Home extends Vue {
  private $ga: any;

  private subNavItems: Array<SubNavItem> = [];

  private showReel = true;

  private showPortfolio = false;

  private showAbout = true;

  private showCredits = false;

  private showContact = true;

  private fullPage = true;

  private showBackToTop = false;

  private loading = false;

  private portfolio: PortfolioModel = {
    mc: [
      {
        id: 1,
        new: false,
        img: "img/folio/the-woohoos.jpg",
        imgFull: "img/folio/the-woohoos-portrait.jpg",
        title: "The Woohoos",
      },
      {
        id: 2,
        new: false,
        img: "img/folio/dog-loves-books.jpg",
        imgFull: "img/folio/dog-loves-books-portrait.jpg",
        title: "Dog Loves Books",
        description:
          'Wrote and Composed Theme Tune: <div style="position: relative; height:100%;"><iframe width="560" height="315" src="https://www.youtube.com/embed/OwmTw6L5Pjw?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
      },
      {
        id: 3,
        new: false,
        img: "img/folio/the-singalings.jpg",
        imgFull: "img/folio/the-singalings-portrait.jpg",
        title: "The Singalings",
      },
      {
        id: 4,
        new: false,
        img: "img/folio/bbc-lost-lands.jpg",
        imgFull: "img/folio/bbc-lost-lands-portrait.jpg",
        title: "BBC Lost Lands",
      },
      {
        id: 5,
        new: false,
        img: "img/folio/faith.jpg",
        imgFull: "img/folio/faith-portrait.jpg",
        title: "Faith",
      },
      {
        id: 6,
        new: false,
        img: "img/folio/slumberless.jpg",
        imgFull: "img/folio/slumberless-portrait.jpg",
        title: "Slumberless",
      },
      {
        id: 7,
        new: false,
        img: "img/folio/dangerously-ever-after.jpg",
        imgFull: "img/folio/dangerously-ever-after-portrait.jpg",
        title: "Dangerously Ever After",
      },
      {
        id: 8,
        new: false,
        img: "img/folio/time-space-reflections.jpg",
        imgFull: "img/folio/time-space-reflections-portrait.jpg",
        title: "Time Space Reflections",
      },
      {
        id: 9,
        new: false,
        img: "img/folio/drip.jpg",
        imgFull: "img/folio/drip-portrait.jpg",
        title: "Drip",
      },
      {
        id: 10,
        new: false,
        img: "img/folio/co-op-bank.jpg",
        imgFull: "img/folio/co-op-bank-portrait.jpg",
        title: "Co-op Bank",
        subTitle: '"It\'s good to be different" Ad Campaign',
      },
    ],
  };

  private headerContent = {
    headline: "Rob Penrose",
    subHeading: "Composer &amp; Sound Designer",
    //iframeSource: "https://www.youtube.com/embed/OwmTw6L5Pjw?controls=0",
    portfolio: this.portfolio,
  };

  private aboutContent = {
    header: "Rob Penrose - Composer & Sound Designer",
    content: `
      Rob Penrose is a Childrens TV Music Composer with over 11 years of experience in the industry; embracing many roles with credits as a Sound Designer, Dubbing Mixer, Voice Director, Voice Actor, Music Supervisor and Composer.
      <br /><br />
      He is a multi-instrumentalist, proficient on guitar, piano and drums and has also been cast for singing parts in various animated TV shows and short films.
      <br /><br />
      Most recently, he enjoyed writing 26 songs for brand new Sky Kids series 'The Singalings' and also voices all 3 characters.<br />
      He was the composer for the popular Cbeebies show 'Dog Loves Books', having written and produced the theme song and underscore for the whole series.
      <br /><br />
      <img src="img/about-rob.jpg" />`,
  };

  created(): void {
    window.scrollTo(0, 0); // eslint-disable-line no-undef
    window.addEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef

    this.subNavItems.push({ href: "#top", title: "Top" });
    if (this.showReel) {
      this.subNavItems.push({ href: "#reel", title: "Reel" });
    }
    if (this.showPortfolio) {
      this.subNavItems.push({
        href: "#portfolio",
        title: "Portfolio",
      });
    }
    if (this.showAbout) {
      this.subNavItems.push({ href: "#about", title: "About" });
    }
    if (this.showCredits) {
      this.subNavItems.push({ href: "#credits", title: "Credits" });
    }
    if (this.showContact) {
      this.subNavItems.push({ href: "#contact", title: "Contact" });
    }
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef
  }

  public track(): void {
    this.$ga.page("/");
  }

  public handleScroll(): void {
    if (
      document.body.scrollTop > 400 || // eslint-disable-line no-undef
      document.documentElement.scrollTop > 400 // eslint-disable-line no-undef
    ) {
      this.showBackToTop = true;
    } else {
      this.showBackToTop = false;
    }
  }

  public setLoading(): void {
    this.loading = true;
  }
}
