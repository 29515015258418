




































import { Component, Vue } from "vue-property-decorator";
import SiteNavigation from "@/components/SiteNavigation.vue";
import ContactForm from "@/components/widgets/ContactForm.vue";
import SiteFooter from "@/components/SiteFooter.vue";

@Component({
  components: {
    SiteNavigation,
    ContactForm,
    SiteFooter,
  },
})
export default class VoiceActing extends Vue {
  private $ga: any;

  private subNavItems = [];

  private showContact = true;

  private showBackToTop = false;

  private loading = false;

  created(): void {
    window.scrollTo(0, 0); // eslint-disable-line no-undef
    window.addEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef
  }

  public track(): void {
    this.$ga.page("/blog");
  }

  public handleScroll(): void {
    if (
      document.body.scrollTop > 400 || // eslint-disable-line no-undef
      document.documentElement.scrollTop > 400 // eslint-disable-line no-undef
    ) {
      this.showBackToTop = true;
    } else {
      this.showBackToTop = false;
    }
  }

  public setLoading(): void {
    this.loading = true;
  }
}
