

















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SiteFooter extends Vue {
  @Prop({ default: [] }) subNavItems!: Array<any>;
  @Prop({ default: false }) showContact!: boolean;
  private currentYear = new Date().getFullYear();

  public scrollToTop(): void {
    window.scrollTo(0, 0); // eslint-disable-line no-undef
  }

  public isActive(currentHash: string, index: number): boolean {
    const urlHash = this.$route.hash;
    currentHash = currentHash.replace(/^.+#/, "#");
    return urlHash === currentHash || (urlHash === "" && index === 0);
  }
}
