
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PortfolioModal extends Vue {
  @Prop({
    required: true,
    default: {
      id: 0,
      new: false,
      url: "#",
      img: "img/folio/default.jpg",
      imgFull: "img/folio/default-portrait.jpg",
      title: "Loading...",
      description: "Loading...",
      cta: "Loading...",
    },
  })
  activeItem!: any;

  private $modal: any;

  public closeModal(modal: string): void {
    this.$modal.hide(modal);
  }
}
