
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainHeader extends Vue {
  @Prop({ default: "" }) anchorLink!: string;

  @Prop({ default: "714767505" }) playlistId!: string;

  get currentPlaylistId() {
    return this.playlistId ? this.playlistId : false;
  }
}
