



















































































/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import PortfolioModal from "@/components/PortfolioModal.vue";
import { PortfolioItemsModel, PortfolioModel } from "@/models";

@Component({
  components: {
    PortfolioModal,
  },
})
export default class MainHeader extends Vue {
  @Prop() private anchorLink!: string;
  @Prop() private portfolio!: PortfolioModel;

  private activePortfolio = "all";

  private $modal: any;

  private activeItem: Array<object> = [];

  private portfolioItems: PortfolioItemsModel = [
    {
      id: 0,
      new: false,
      url: "#",
      img: "img/folio/default.jpg",
      imgFull: "img/folio/default-portrait.jpg",
      title: "Loading...",
      description: "Loading...",
      cta: "Loading...",
    },
  ];

  mounted(): void {
    this.changePortfolio(this.activePortfolio);
    this.activeItem = [this.portfolioItems];
  }

  public changePortfolio(type: string): void {
    this.activePortfolio = type;
    this.portfolioItems =
      type === "all"
        ? [].concat(this.portfolio?.va ?? []).concat(this.portfolio?.mc ?? [])
        : this.portfolio[type];
  }

  public switchPortfolio(event: any): void {
    let type = event.target.getAttribute("data-type");

    if (!this.portfolio[type] && type !== "all") {
      type = "all";
    }
    this.changePortfolio(type);
  }

  public viewPortfolioItem(portfolioId: number): void {
    Object.keys(this.portfolioItems).forEach((key) => {
      if (this.portfolioItems[key].id === portfolioId) {
        this.activeItem = this.portfolioItems[key];
      }
    });

    this.$modal.show("portfolio-modal");
  }

  public hasMultipleOptions(): boolean {
    return Object.keys(this.portfolio).length > 1;
  }

  public hasContent(type: string): boolean {
    return (
      typeof this.portfolio[type] != "undefined" && Object.keys(this.portfolio[type]).length > 1
    );
  }

  public getItemClass(key: number): string {
    const totalItems = Object.keys(this.portfolioItems).length;
    const groupSize = 3;
    const lastGroupStartIndex = Math.floor(totalItems / groupSize) * groupSize;

    if (key < lastGroupStartIndex) return "col-md-4 col-lg-4";

    const itemsInLastGroup = totalItems - lastGroupStartIndex;
    const missingItems = groupSize - itemsInLastGroup;

    return missingItems === 2
      ? "col-md-4 offset-md-4 col-lg-4 offset-lg-4"
      : missingItems === 1
      ? "col-md-4 offset-md-2 col-lg-4 offset-lg-2"
      : "col-md-4 col-lg-4";
  }
}
