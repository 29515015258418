









































































import { Component, Vue } from "vue-property-decorator";
import SiteNavigation from "@/components/SiteNavigation.vue";
import MainHeader from "@/components/widgets/MainHeader.vue";
import Showreel from "@/components/widgets/Showreel.vue";
import Portfolio from "@/components/widgets/Portfolio.vue";
import About from "@/components/widgets/About.vue";
import Credits from "@/components/widgets/Credits.vue";
import ContactForm from "@/components/widgets/ContactForm.vue";
import SiteFooter from "@/components/SiteFooter.vue";

@Component({
  components: {
    SiteNavigation,
    MainHeader,
    Showreel,
    Portfolio,
    About,
    Credits,
    ContactForm,
    SiteFooter,
  },
})
export default class Home extends Vue {
  private $ga: any;

  private subNavItems = [];

  private showBackToTop = false;

  private showContact = false;

  private loading = false;

  private headerContent = {
    headline: "Rob Penrose",
    subHeading: "Composer &amp; Sound Designer",
    iframeSource: "https://www.youtube.com/embed/OwmTw6L5Pjw?controls=0",
  };

  created(): void {
    window.addEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef
  }

  public track(): void {
    this.$ga.page("/");
  }

  public handleScroll(): void {
    if (
      document.body.scrollTop > 400 || // eslint-disable-line no-undef
      document.documentElement.scrollTop > 400 // eslint-disable-line no-undef
    ) {
      this.showBackToTop = true;
    } else {
      this.showBackToTop = false;
    }
  }

  public setLoading(): void {
    this.loading = true;
  }
}
