import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/vue-analytics';
import './plugins/modal-vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './plugins/bootstrap-vue';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
