

































import { Component, Prop, Vue } from "vue-property-decorator";
import Portfolio from "@/components/widgets/Portfolio.vue";

@Component({
  components: {
    Portfolio,
  },
})
export default class MainHeader extends Vue {
  @Prop() private anchorLink!: string;

  @Prop() private componentContent!: string;
}
