















































import { Component, Vue } from "vue-property-decorator";
import SiteNavigation from "@/components/SiteNavigation.vue";
import MainHeader from "@/components/widgets/MainHeader.vue";
import Showreel from "@/components/widgets/Showreel.vue";
import Portfolio from "@/components/widgets/Portfolio.vue";
import About from "@/components/widgets/About.vue";
import Credits from "@/components/widgets/Credits.vue";
import ContactForm from "@/components/widgets/ContactForm.vue";
import SiteFooter from "@/components/SiteFooter.vue";

import { SubNavItem } from "@/interfaces/Navigation.interface";

@Component({
  components: {
    SiteNavigation,
    MainHeader,
    Showreel,
    Portfolio,
    About,
    Credits,
    ContactForm,
    SiteFooter,
  },
})
export default class Home extends Vue {
  private $ga: any;

  private subNavItems: Array<SubNavItem> = [];

  private showReel = false;

  private showPortfolio = false;

  private showAbout = true;

  private showCredits = true;

  private showContact = true;

  private fullPage = true;

  private showBackToTop = false;

  private loading = false;

  private headerContent = {
    headline: "Rob Penrose",
    subHeading: "Freelance Dubbing Mixer",
  };

  private aboutContent = {
    header: "Rob Penrose - Freelance Dubbing Mixer",
    content: `
    <div class="row align-items-start">
      <div class="order-2 order-md-1 col-12 col-md-6 text-left">
        <ul class="bullets">
          <li>10+ years experience</li>
          <li>Based in the North East</li>
          <li>Home Pro Tools Ultimate dubbing studio</li>
          <li>Remote link up options available</li>
          <li>Experienced in Documentary, Reality TV, Animation, Current Affair, Childrens TV, Sound Design</li>
          <li>Credits include Songs of Praise, Rip off Britain, Postman Pat, Dinopaws, Kelvin's Big Farming Adventure</li>
        </ul>
      </div>
      <div class="order-1 order-md-2 col-12 col-md-6">
        <img src="img/rob-headshot.jpg" />
      </div>
    </div>`,
  };

  created(): void {
    window.scrollTo(0, 0); // eslint-disable-line no-undef
    window.addEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef

    if (this.showReel) {
      this.subNavItems.push({ href: "#reel", title: "Reel" });
    }
    if (this.showPortfolio) {
      this.subNavItems.push({ href: "#portfolio", title: "Portfolio" });
    }
    if (this.showAbout) {
      this.subNavItems.push({ href: "#about", title: "About" });
    }
    if (this.showCredits) {
      this.subNavItems.push({ href: "#credits", title: "Credits" });
    }
    if (this.showContact) {
      this.subNavItems.push({ href: "#contact", title: "Contact" });
    }
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef
  }

  public track(): void {
    this.$ga.page("/");
  }

  public handleScroll(): void {
    if (
      document.body.scrollTop > 400 || // eslint-disable-line no-undef
      document.documentElement.scrollTop > 400 // eslint-disable-line no-undef
    ) {
      this.showBackToTop = true;
    } else {
      this.showBackToTop = false;
    }
  }

  public setLoading(): void {
    this.loading = true;
  }
}
