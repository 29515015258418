












































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainHeader extends Vue {
  @Prop() private anchorLink!: string;

  /*
    {
      what: "",
      where: "",
      duration: "",
      type: "",
    },
  */
  private credits: Array<any> = [
    {
      type: "Animation Credits (Dubbing/Sound Design):",
      credits: [
        {
          what: "Tinpo",
          where: "Cbeebies",
          duration: "104 x 5 min",
          type: "Voice Director/Sound Design/Mix",
        },
        {
          what: "World According To Grandpa",
          where: "Channel 5 Milkshake",
          duration: "25x10min",
          type: "Sound Design/Mix",
        },
        {
          what: "Toby's Travelling Circus",
          where: "Komixx Entertainment",
          duration: "52 x 10min",
          type: "Tracklay/Mix/Voice Record",
        },
        {
          what: "Wanda and the Alien",
          where: "Komixx Entertainment",
          duration: "52 x 10min",
          type: "Tracklay/Mix/Voice Record",
        },
        {
          what: "Cloudbabies",
          where: "Hoho Productions",
          duration: "52x10min",
          type: "Tracklay/Mix/Voice Record",
        },
        {
          what: "Tree Fu Tom",
          where: "Freemantle Media",
          duration: "52x10min",
          type: "Tracklay/Mix",
        },
        {
          what: "Mansour",
          where: "Fanar",
          duration: "52 x 10min",
          type: "Tracklay/Mix",
        },
        {
          what: "The Long Long Holiday Les Armateurs",
          where: "",
          duration: "1 Season",
          type: "Voice Record Reversioning",
        },
        {
          what: "Once Upon A Time",
          where: "Procidis",
          duration: "26x28min",
          type: "Voice Record Re-versioning",
        },
        {
          what: "Mourinho's Special Ones",
          where: "",
          duration: "10 episodes",
          type: "5 person Voice Records",
        },
        {
          what: "Dinopaws",
          where: "Kindle Entertainment/Laughing Gravy",
          duration: "52 x 10min",
          type: "Tracklay/Mix/Sound Design",
        },
        {
          what: "Dinosaur Train",
          where: "Nick Jr",
          duration: "182 x 10min",
          type: "Voice Record Re-Versioning",
        },
        {
          what: "Old Jacks Boat",
          where: "BBC/Cbeebies",
          duration: "3 seasons",
          type: "Tracklay/Mix",
        },
        {
          what: "Bendik & The Monster",
          where: "",
          duration: "Short Film",
          type: "Voice Record Reversioning",
        },
        {
          what: "Treasures of Kerub",
          where: "Ankama",
          duration: "52 x 10min",
          type: "Voice Record Reversioning",
        },
        {
          what: "Simon",
          where: "Go-N",
          duration: "1 season",
          type: "Voice Record Reversioning",
        },
        {
          what: "Lost Lands",
          where: "BBC Bitesize",
          duration: "1x 30 min",
          type: "Music Composer",
        },
        {
          what: "Gigglebiz",
          where: "BBC/Cbeebies",
          duration: "10 x 15 min",
          type: "Tracklay/Mix",
        },
        {
          what: "The Scent of Carrots",
          where: "",
          duration: "1 x 45min",
          type: "Song Recordings/Pitch correction and mixing",
        },
        {
          what: "Kate & Mim-Mim",
          where: "",
          duration: "100 x 10min",
          type: "BBC/Cbeebies - Voice Record Reversioning/Mix",
        },
        {
          what: "Woozle & Pip",
          where: "Cake",
          duration: "2 seasons",
          type: "Voice Record Reversioning/Mix",
        },
        {
          what: "Mofy",
          where: "Channel 5 Milkshake",
          duration: "3 series",
          type: "Voice Record Reversioning/Mix",
        },
        {
          what: "RaaRaa The Noisy Lion",
          where: "Dreamworks",
          duration: "52 x 10min",
          type: "Tracklay/Mix",
        },
        {
          what: "Postman Pat",
          where: "Dreamworks",
          duration: "52 x 15min",
          type: "Tracklay/Mix",
        },
        {
          what: "Baby Riki",
          where: "",
          duration: "104 x 5min",
          type: "Voice Record Reversioning",
        },
        {
          what: "Dog Loves Books",
          where: "",
          duration: "52 x 7min",
          type: "Music Composer & Sound Designer",
        },
      ],
    },
    {
      type: "Mixing Credits",
      credits: [
        {
          what: "Peter Crouch - Save Our Beautiful Game",
          where: "Discovery",
        },
        {
          what: "We Are England",
          where: "BBC ONE",
        },
        {
          what: "BBC Bitesize 2021-2022",
          where: "BBC",
        },
        {
          what: "Walking With..",
          where: "BBC2",
          duration: "Series",
        },
        {
          what: "Kelvins Big Farming Adventure",
          where: "BBC ONE",
        },
        {
          what: "Britains Favourite 90s Hits",
          where: "Viacom – C5",
        },
        {
          what: "Songs of Praise",
          where: "BBC ONE",
          duration: "20x 30min",
          type: "Audio Mix",
        },
        {
          what: "Motor Pickers",
          where: "Quest",
          duration: "Season 2",
        },
        {
          what: "Criminally Funny",
          where: "Comedy Central",
        },
        {
          what: "Greatest Hits of The 80s",
          duration: "2 x90 min",
          type: "Audio Mix",
        },
        {
          what: "Inside Aldi",
          where: "Channel 5",
          type: "Mix",
        },
        {
          what: "Class Dismissed",
          where: "CBBC",
          duration: "Series 2-4",
          type: "Dialogue Editor + Tracklay, Mix",
        },
        {
          what: "PDSA - The Peoples Vet, Blakeway North",
          where: "Channel 4",
          duration: "20 x 45min",
          type: "Audio Mix",
        },
        {
          what: "Julian Clary’s Greatest Ever Christmas Ads",
          where: "Channel 5",
          duration: "1 x 90min",
          type: "Audio Mix",
        },
        {
          what: "Panorama",
          where: "BBC 1",
          duration: "2x30min",
          type: "Audio Mix",
        },
        {
          what: "Pound Shop Wars",
          where: "BBC 1",
          duration: "1x30min",
          type: "Audio Mix",
        },
        {
          what: "Treasure Champs",
          where: "Cbeebies",
          duration: "Series 1",
          type: "Audio Mix",
        },
        {
          what: "Battling the Bailiffs",
          where: "Nine Lives Media",
          type: "Audio Mix",
        },
        {
          what: "Bargain Loving Brits in The Sun",
          where: "Blakeway",
          type: "Audio Mix",
        },
        {
          what: "Failed By The NHS",
          where: "Nine Lives Media",
          type: "Audio Mix",
        },
        {
          what: "Tonight: Plane Drunk",
          where: "BBC One",
          type: "Audio Mix",
        },
        {
          what: "Elf The Musical (Making of)",
          where: "Channel 5",
          type: "Audio Mix",
        },
        {
          what: "Apprentice for a Day",
          where: "BBC",
          type: "Audio Mix",
        },
        {
          what: "Crimes That Shook Britain",
          duration: "Various Episodes",
          type: "Audio Mix",
        },
        {
          what: "Teen Mom",
          where: "MTV",
          type: "Voice Records",
        },
        {
          what: "BBC Comedy Sketches",
          duration: "100+ Short Sketches",
          type: "Voice Records",
        },
        {
          what: "Benidorm ER",
          where: "Channel 5",
          duration: "Series 1-4",
          type: "Audio Mix",
        },
        {
          what: "Myra Hindley: The Untold Story",
          where: "Nine Lives Media, C5",
          duration: "3x60min",
          type: "Audio Mix",
        },
        {
          what: "Nightmare in Suburbia",
          where: "Nine Lives Media, Crime and Investigation Channel",
          duration: "Series, 40min",
          type: "Mix",
        },
        {
          what: "Share a Story",
          where: "CITV",
          duration: "1x50min",
          type: "Audio Mix",
        },
        {
          what: "Licence to Kill",
          where: "BBC Production, BBC 3",
          duration: "1x60min",
          type: "Audio Mix",
        },
        {
          what: "Perspectives - Frida Kahlo presented by Emeli Sande",
          where: "Blakeway North, ITV",
          duration: "1x60min",
          type: "Audio Mix",
        },
        {
          what: "Don’t Call me Crazy",
          where: "Platform Productions, BBC 3",
          duration: "2x60min",
          type: "Audio Mix",
        },
        {
          what: "The Briefs",
          where: "Chameleon TV, ITV",
          duration: "2x60min",
          type: "Audio Mix",
        },
        {
          what: "The More You Ignore Me, Feature Film",
          type: "Foley Artist + Recording",
        },
        {
          what: "Starfish, Feature Film",
          type: "Foley Artist + Recording",
        },
        {
          what: "Tim Burton Exhibition Animation",
          type: "Audio Mix",
        },
        {
          what: "Washington Museum of the Bible Virtual Experience",
          type: "Tracklay + 5.1 mix",
        },
        {
          what: "Habit",
          type: "Foley Artist + Recording",
        },
        {
          what: "Free Rein",
          where: "Netflix Drama",
          type: "5.1 surround up-mix",
        },
        {
          what: "Jane McDonald – Cruising",
          type: "Audio Mix",
        },
        {
          what: "Baby Club",
          where: "Cbeebies",
          duration: "Series 1, 2 + 3",
          type: "Audio Mix",
        },
        {
          what: "Peaky Blinders – Behind The Scenes DVD Extras",
          type: "Audio Mix",
        },
        {
          what: "The A Word - Behind The Scenes DVD Extras",
          type: "Audio Mix",
        },
        {
          what: "Wanderlust - Behind The Scenes DVD Extras",
          type: "Audio Mix",
        },
      ],
    },
  ];
}
