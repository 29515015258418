


















































import { Component, Vue } from "vue-property-decorator";
import SiteNavigation from "@/components/SiteNavigation.vue";
import MainHeader from "@/components/widgets/MainHeader.vue";
import Showreel from "@/components/widgets/Showreel.vue";
import About from "@/components/widgets/About.vue";
import ContactForm from "@/components/widgets/ContactForm.vue";
import ContactFormAgent from "@/components/widgets/ContactFormAgent.vue";
import SiteFooter from "@/components/SiteFooter.vue";

import { SubNavItem } from "@/interfaces/Navigation.interface";

@Component({
  components: {
    SiteNavigation,
    MainHeader,
    Showreel,
    About,
    ContactForm,
    ContactFormAgent,
    SiteFooter,
  },
})
export default class VoiceActing extends Vue {
  private $ga: any;

  private subNavItems: Array<SubNavItem> = [];

  private showReel = true;

  private showPortfolio = false;

  private showAbout = false;

  private showCredits = false;

  private showContact = true;

  private showAgentContact = false;

  private fullPage = true;

  private showBackToTop = false;

  private loading = false;

  private headerContent = {
    headline: "Rob Penrose",
    subHeading: "Voice Actor",
    iframeSource: "https://www.youtube.com/embed/kZctnGCvo9Y?controls=0",
  };

  private aboutContent = {
    header: "Rob Penrose - Voice Actor",
    content: `
      Rob Penrose is a Film &amp; TV Music Composer with over 10 years of experience in the industry; embracing many roles with
      credits as a Sound Designer, Dubbing Mixer, Voice Director, Voice Actor, Music Supervisor and Composer.
      <br /><br />
      He is a multi-instrumentalist, proficient on guitar, piano and drums and has also been cast for singing parts in various
      animated TV shows and short films.
      <br /><br />
      Most recently, Rob wrote music and lyrics for the theme song of popular Cbeebies show “Dog Loves Books”, also providing
      underscore and songs for 52 episodes of the series, each of which required a different genre of music.
      <br /><br />
      <img src="img/about-rob.jpg" />`,
  };

  created(): void {
    window.scrollTo(0, 0); // eslint-disable-line no-undef
    window.addEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef

    this.subNavItems.push({ href: "#top", title: "Top" });
    if (this.showReel) {
      this.subNavItems.push({ href: "#reel", title: "Reel" });
    }
    if (this.showPortfolio) {
      this.subNavItems.push({ href: "#portfolio", title: "Portfolio" });
    }
    if (this.showAbout) {
      this.subNavItems.push({ href: "#about", title: "About" });
    }
    if (this.showCredits) {
      this.subNavItems.push({ href: "#credits", title: "Credits" });
    }
    if (this.showContact || this.showAgentContact) {
      this.subNavItems.push({ href: "#contact", title: "Contact" });
    }
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.handleScroll); // eslint-disable-line no-undef
  }

  public track(): void {
    this.$ga.page("/voice-acting");
  }

  public handleScroll(): void {
    if (
      document.body.scrollTop > 400 || // eslint-disable-line no-undef
      document.documentElement.scrollTop > 400 // eslint-disable-line no-undef
    ) {
      this.showBackToTop = true;
    } else {
      this.showBackToTop = false;
    }
  }

  public setLoading(): void {
    this.loading = true;
  }
}
