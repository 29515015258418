






















import { Component, Prop, Vue } from 'vue-property-decorator';
import DataService from '@/services/DataService';

interface Contact {
  id: string;
  fullname: string;
  email: string;
  phonenumber: string;
  message: string;
}

@Component
export default class ContactForm extends Vue {
  @Prop() private source!: string;

  private trueConstant = true;

  private blurFields = {
    nameBlur: false,
    emailBlur: false,
    numberBlur: false,
    messageBlur: false,
  };

  private contactSuccess = false;

  private errorMessage = '';

  private contact: Contact = {
    id: '',
    fullname: '',
    email: '',
    phonenumber: '',
    message: '',
  };

  public changeValue(key: string): void {
    // eslint-disable-next-line
    this.blurFields[`${key}Blur`] = true;
  }

  get namestate(): boolean | null {
    return !this.blurFields.nameBlur || this.contact.fullname.length === 0 ? null : this.contact.fullname.length >= 3;
  }

  get emailstate(): boolean | null {
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const emailRegex = new RegExp(reg);

    return !this.blurFields.emailBlur || this.contact.email.length === 0 ? null : emailRegex.test(this.contact.email);
  }

  get numberstate(): boolean | null {
    const reg = /^\d+$/;
    const phoneRegex = new RegExp(reg);

    return !this.blurFields.numberBlur || this.contact.phonenumber.length === 0 ? null : phoneRegex.test(this.contact.phonenumber) && this.contact.phonenumber.length >= 9;
  }

  get messagestate(): boolean | null {
    return !this.blurFields.messageBlur || this.contact.message.length === 0 ? null : this.contact.message.length >= 4;
  }

  get submitState(): boolean {
    return this.namestate === true && this.emailstate === true && this.numberstate === true && this.messagestate === true;
  }

  public contactSubmission(val: boolean) {
    this.contactSuccess = val;
    if (val === false) {
      this.contact.id = '';
      this.contact.fullname = '';
      this.contact.email = '';
      this.contact.phonenumber = '';
      this.contact.message = '';
    }
  }

  public nameInvalid(): string {
    if (this.contact.fullname.length > 0) {
      return 'Enter at least 4 characters.';
    }
    return 'Please enter something.';
  }

  public emailInvalid(): string {
    if (this.contact.email.length > 0) {
      return 'Enter at least 4 characters.';
    }
    return 'Please enter something.';
  }

  public numberInvalid(): string {
    if (this.contact.phonenumber.length > 0) {
      return 'Enter at least 4 characters.';
    }
    return 'Please enter something.';
  }

  public messageInvalid(): string {
    if (this.contact.message.length > 0) {
      return 'Enter at least 4 characters.';
    }
    return 'Please enter something.';
  }

  public doContact(): void {
    // curl -H "Content-Type: application/json" -X POST -d '{"fullname":"Shekhar Gulati","email": "shekhargulati84@gmail.com", "phonenumber":"12"}' https://rdxzldv6p5.execute-api.eu-west-2.amazonaws.com/dev/contact
    const one = 1;
    if (this.submitState || one === 1) {
      this.$emit('updateLoading', true);

      const data = {
        fullname: this.contact.fullname,
        email: this.contact.email,
        phonenumber: this.contact.phonenumber,
        details: this.contact.message,
      };

      data.details = `${data.details}<br /><br /><strong>Page Source:</strong> ${this.source}`;

      const dsData = {
        body: `${JSON.stringify(data)}`,
      };

      DataService.contact(`${JSON.stringify(dsData)}`)
        .then((response) => {
          this.$emit('updateLoading', false);
          const resp = response.data;
          console.log(resp);
          const result = JSON.parse(resp.body);

          console.log(result);
          console.log(result.error);

          if (result.error) {
            this.errorMessage = result.message;
          } else {
            if (result.contactId) {
              this.contact.id = result.contactId;
            }
            this.contactSuccess = resp.statusCode === 200;
            setTimeout(() => this.contactSubmission(false), 10000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
}
