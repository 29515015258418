











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SiteFooter extends Vue {
  private currentYear = new Date().getFullYear();
}
