import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Composer from '../views/Composer.vue';
import VoiceActing from '../views/VoiceActing.vue';
import Dubbing from '../views/Dubbing.vue';
import Blog from '../views/Blog.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/composer',
    name: 'Composer',
    component: Composer,
  },
  {
    path: '/voice-acting',
    name: 'Voice Acting',
    component: VoiceActing,
  },
  {
    path: '/dubbing',
    name: 'Dubbing',
    component: Dubbing,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: "/:catchAll(.*)",
    name: "Not found",
    component: Home,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
