import { render, staticRenderFns } from "./MainHeader.vue?vue&type=template&id=487ab683&scoped=true&"
import script from "./MainHeader.vue?vue&type=script&lang=ts&"
export * from "./MainHeader.vue?vue&type=script&lang=ts&"
import style0 from "./MainHeader.vue?vue&type=style&index=0&id=487ab683&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487ab683",
  null
  
)

export default component.exports